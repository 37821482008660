// const api_link = "http://localhost:60020";
const api_link = "https://arrowtrad.com:60020";
const EntryKeyBroker = "23d0866b3009a2a0769cf12b09819e0b";

export default class Backend {
  login(data) {
    return fetch(api_link + "/login", {
      method: "POST",
      headers: {
        EntryKeyBroker: EntryKeyBroker,
        "Content-Type": "application/json",
        accept: "application/json",
        id: data.id,
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_marquee(data) {
    return fetch(api_link + "/load_marquee", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        server: data.server,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_all_data(data) {
    return fetch(api_link + "/load_all_data", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  other(data) {
    return fetch(api_link + "/other", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  live_trade(data) {
    return fetch(api_link + "/live_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol(data) {
    return fetch(api_link + "/load_symbol", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_summary(data) {
    return fetch(api_link + "/load_summary", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  check_status(data) {
    return fetch(api_link + "/check_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_future(data) {
    return fetch(api_link + "/client_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_mcx(data) {
    return fetch(api_link + "/client_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_options(data) {
    return fetch(api_link + "/client_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client(data) {
    return fetch(api_link + "/load_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_master(data) {
    return fetch(api_link + "/load_master", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_super(data) {
    return fetch(api_link + "/load_super", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_admin(data) {
    return fetch(api_link + "/load_admin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  single_client(data) {
    return fetch(api_link + "/single_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_ledger(data) {
    return fetch(api_link + "/client_ledger", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_login_log(data) {
    return fetch(api_link + "/client_login_log", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_trade_log(data) {
    return fetch(api_link + "/client_trade_log", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_status(data) {
    return fetch(api_link + "/client_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_autosq_status(data) {
    return fetch(api_link + "/client_autosq_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_onlysq_status(data) {
    return fetch(api_link + "/client_onlysq_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_notification_status(data) {
    return fetch(api_link + "/client_notification_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_watch_status(data) {
    return fetch(api_link + "/client_watch_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_block(data) {
    return fetch(api_link + "/load_block", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_index(data) {
    return fetch(api_link + "/load_index", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_standing(data) {
    return fetch(api_link + "/load_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_standing_client(data) {
    return fetch(api_link + "/load_standing_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_standing_trade(data) {
    return fetch(api_link + "/load_standing_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_standing(data) {
    return fetch(api_link + "/client_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client_standing(data) {
    return fetch(api_link + "/load_client_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_standing(data) {
    return fetch(api_link + "/symbol_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_standing(data) {
    return fetch(api_link + "/load_symbol_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_standing_parking(data) {
    return fetch(api_link + "/load_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_standing_parking(data) {
    return fetch(api_link + "/client_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client_standing_parking(data) {
    return fetch(api_link + "/load_client_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_standing_parking(data) {
    return fetch(api_link + "/symbol_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_standing_parking(data) {
    return fetch(api_link + "/load_symbol_standing_parking", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_sort_trade(data) {
    return fetch(api_link + "/load_sort_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_trade(data) {
    return fetch(api_link + "/load_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_sort_trade(data) {
    return fetch(api_link + "/client_sort_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_sort_trade(data) {
    return fetch(api_link + "/symbol_sort_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_trade(data) {
    return fetch(api_link + "/client_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_trade(data) {
    return fetch(api_link + "/symbol_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_symbol_standing_trade(data) {
    return fetch(api_link + "/load_symbol_standing_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_client_standing_trade(data) {
    return fetch(api_link + "/load_client_standing_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_client_trade(data) {
    return fetch(api_link + "/load_client_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_trade(data) {
    return fetch(api_link + "/load_symbol_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_sort_trade(data) {
    return fetch(api_link + "/load_sort_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_sort_trade(data) {
    return fetch(api_link + "/client_sort_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_sort_trade(data) {
    return fetch(api_link + "/symbol_sort_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_client_sort_trade(data) {
    return fetch(api_link + "/load_client_sort_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_sort_trade(data) {
    return fetch(api_link + "/load_symbol_sort_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_pending(data) {
    return fetch(api_link + "/pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_client_pending(data) {
    return fetch(api_link + "/load_client_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_pending(data) {
    return fetch(api_link + "/load_symbol_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_pending(data) {
    return fetch(api_link + "/client_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_pending(data) {
    return fetch(api_link + "/symbol_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_holding(data) {
    return fetch(api_link + "/load_holding", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_brokerage(data) {
    return fetch(api_link + "/symbol_brokerage", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_brokerage_update(data) {
    return fetch(api_link + "/symbol_brokerage_update", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_report(data) {
    return fetch(api_link + "/client_report", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  brokerage_report(data) {
    return fetch(api_link + "/brokerage_report", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  broker_list(data) {
    return fetch(api_link + "/broker_list", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_broker(data) {
    return fetch(api_link + "/load_broker", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_summary_broker(data) {
    return fetch(api_link + "/load_summary_broker", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  broker_client(data) {
    return fetch(api_link + "/broker_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }


  edit_broker_details(data) {
    return fetch(api_link + "/edit_broker_details", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  broker_status(data) {
    return fetch(api_link + "/broker_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  broker_ledger(data) {
    return fetch(api_link + "/broker_ledger", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  broker_login_log(data) {
    return fetch(api_link + "/broker_login_log", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_broker(data) {
    return fetch(api_link + "/client_broker", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_broker(data) {
    return fetch(api_link + "/add_broker", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  broker_update(data) {
    return fetch(api_link + "/broker_update", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  broker_remove(data) {
    return fetch(api_link + "/broker_remove", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  broker_brokerage(data) {
    return fetch(api_link + "/broker_brokerage", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  carry_standing(data) {
    return fetch(api_link + "/carry_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  cancel_pending(data) {
    return fetch(api_link + "/cancel_pending", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  buy_sell(data) {
    return fetch(api_link + "/buy_sell", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  limit(data) {
    return fetch(api_link + "/limit", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  stoploss(data) {
    return fetch(api_link + "/stoploss", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  permission(data) {
    return fetch(api_link + "/permission", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client_report(data) {
    return fetch(api_link + "/load_client_report", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  profile(data) {
    return fetch(api_link + "/profile", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  ledger(data) {
    return fetch(api_link + "/ledger", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyBroker: EntryKeyBroker,
        server: data.server,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
}
